.slick-dots {
  // bottom: 7px;
  // background: rgba(0, 0, 0, 0.2);
  // padding: 10px;
  // bottom: unset;
  bottom: -30px;
  @media screen and (max-width: 767px) {
    // padding: 5px;
  }
  li {
    margin: 0 3px;
    button {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      position: relative;

      &::before {
        content: "";
        font-size: 0;
        opacity: 1;
        border: 1px solid #ffb400;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        transition: 0.3s ease-in-out;
      }
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        transform: scale(0);
        background: #ffb400;
        transition: 0.3s ease-in-out;
        border-radius: inherit;
        z-index: 5;
      }
    }
    &.slick-active {
      button {
        &::after {
          transform: scale(0.6);
        }
      }
    }
  }
}
// .slick-arrow {
//   display: none !important;
// }
.slick-list{
  // border: 2px solid #72b626;
  box-shadow: 0 0 10px #ccc;
  border-radius: 5px;
}
.slide_item{
  outline: none;
  display: unset !important;
  &:focus-visible {
    outline: none;
  }
  img {
    &:focus-visible {
      outline: none;
    } 
  }
}
.slick-prev:before, .slick-next:before{
  color: gray;
}
